.bg-gray-100 {
    background-color: #f8f9fa !important;
}
.navbar-vertical.navbar-expand-xs.fixed-left {
    left: 0;
}
.navbar-vertical.navbar-expand-xs {
    display: block;
    position: fixed;
    top: 0;
    bottom: 0;
    width: 100%;
    max-width: 15.625rem !important;
    overflow-y: auto;
    padding: 0;
    box-shadow: none;
    background-color: #fff;
}
.sidenav {
    z-index: 1050;
}
.main-content, .sidenav {
    transition: all .2s ease-in-out;
}
.sidenav-header {
    height: 4.875rem;
}
.navbar-vertical .navbar-brand-img, .navbar-vertical .navbar-brand > img {
    max-width: 100%;
    max-height: 2rem;
}
.w-15 {
    width: 15% !important;
}
.sidenav .navbar-brand {
    padding: 1.5rem 2rem;
}
.sidenav .navbar-brand, .sidenav .navbar-heading {
    display: block;
}
.navbar .nav-link, .navbar .navbar-brand {
    color: #252f40;
    font-size: .875rem;
}
hr.horizontal.dark {
    background-image: linear-gradient(90deg,transparent,rgba(0,0,0,.4),transparent);
}
hr.horizontal {
    background-color: transparent;
}
hr:not([size]) {
    height: 1px;
}
.navbar-vertical.navbar-expand-xs .navbar-collapse {
    display: block;
    overflow: auto;
}
.navbar-nav {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}
.navbar-vertical.navbar-expand-xs .navbar-nav > .nav-item {
    margin-top: .125rem;
}
.navbar-vertical.navbar-expand-xs .navbar-nav .nav-item {
    width: 100%;
}
.navbar-vertical .navbar-nav > .nav-item .nav-link.active {
    color: #fff;
    background-color: #397bbb;
}
.navbar-vertical .navbar-nav .nav-link.active {
    font-weight: 600;
    box-shadow: 0 20px 27px 0 rgba(0,0,0,.05);
    border-radius: .5rem;
}
.navbar-vertical.navbar-expand-xs .navbar-nav .nav-link {
    padding-top: .675rem;
    padding-bottom: .675rem;
    margin: 0 1rem;
}
.navbar-vertical .navbar-nav .nav-link {
    display: flex;
    align-items: center;
    white-space: nowrap;
}
.navbar-vertical .navbar-nav .nav-link {
    padding-left: 1rem;
    padding-right: 1rem;
    font-weight: 500;
    color: #262e3d;
}
.navbar-vertical .navbar-nav > .nav-item .nav-link.active .icon {
    /* background-image: linear-gradient(310deg,#0c33cb,#0c69cb); */
    color: #397bbb;
}
.border-radius-md {
    border-radius: .5rem;
}
.icon-sm {
    width: 32px;
    height: 32px;
}
.me-2 {
    margin-right: .5rem !important;
}
.shadow {
    box-shadow: 0 .25rem .375rem -.0625rem hsla(0,0%,8%,.12),0 .125rem .25rem -.0625rem hsla(0,0%,8%,.07) !important;
}
.sidenav.fixed-left + .main-content {
    margin-left: 15.7rem;
}
.main-content, .sidenav {
    transition: all .2s ease-in-out;
}
.border-radius-lg {
    border-radius: .75rem;
}
.font-weight-bolder {
    font-weight: 700 !important;
}
.sidenav .sidenav-toggler {
    width: 50px;
    float: right;
    margin-top: 2rem;
}
.sidenav-toggler .sidenav-toggler-line:last-child {
    width: 13px;
    transform: translateX(5px);
}
.sidenav-toggler-inner {
    position: relative;
}
.sidenav-toggler-inner, .sidenav-toggler-line {
    width: 18px;
    transition: all .15s ease;
}
.sidenav-toggler .sidenav-toggler-inner::before {
    transform: scale(1);
}
.sidenav-toggler-inner::before {
    position: absolute;
    top: -14px;
    left: -11px;
    width: 40px;
    height: 40px;
    content: '';
    transition: all .15s cubic-bezier(.68,-.55,.265,1.55);
    transform: scale(0);
    border-radius: 50%;
}
.sidenav-toggler.active .sidenav-toggler-line:first-child {
    width: 13px;
    transform: translateX(5px);
}
.sidenav-toggler-line:not(:last-child) {
    margin-bottom: 3px;
}
.sidenav-toggler-line {
    position: relative;
    display: block;
    height: 2px;
    background-color: #172b4d;
}
.sidenav-toggler-line:not(:last-child) {
    margin-bottom: 3px;
}
.navbar-search-light .input-group {
    border-color: rgba(0,0,0,.6);
    background-color: rgba(255,255,255,.9);
}
.navbar-search .form-control {
    width: 250px;
    transition: all .15s cubic-bezier(.68,-.55,.265,1.55);
    border: 0;
        border-left-color: currentcolor;
        border-left-style: none;
        border-left-width: 0px;
    background-color: transparent;
}
.navbar-search .input-group .input-group-text {
    padding-left: 1rem;
    border: 0;
    background-color: transparent;
}
.navbar-search .input-group {
    transition: background-color .3s linear;
        transition-delay: 0s;
    transition-delay: .15s;
    border: 0 solid;
        border-top-color: currentcolor;
        border-right-color: currentcolor;
        border-bottom-color: currentcolor;
        border-left-color: currentcolor;
    border-radius: 2rem;
}
.breadcrumb-links {
    margin: 0;
    padding: 0;
    background: 0 0;
}
.breadcrumb-dark .breadcrumb-item {
    font-weight: 600;
}
.breadcrumb-item {
    font-size: .875rem;
}
.breadcrumb-dark .breadcrumb-item a {
    color: #f6f9fc;
}
.breadcrumb-dark .breadcrumb-item.active {
    color: #dee2e6;
}
.card {
    margin-bottom: 30px;
    border: 0;
    box-shadow: 0 0 2rem 0 rgba(136,152,170,.15);
}
.card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    border: 1px solid rgba(0,0,0,.05);
    border-radius: .375rem;
    background-color: #fff;
    background-clip: border-box;
}
.card-stats .card-body {
    padding: 1rem 1.5rem;
}
.card-body {
    min-height: 1px;
    padding: 1.5rem;
    flex: 1 1 auto;
}
.card .h2 {
    color: #32325d;
}
.border-bottom {
    border-bottom: 1px solid #e9ecef !important;
        border-bottom-color: rgb(233, 236, 239);
}
.main-content .container-fluid, .main-content .container-lg, .main-content .container-md, .main-content .container-sm, .main-content .container-xl {
    padding-right: 30px !important;
    padding-left: 30px !important;
}
.mt--6, .my--6 {
    margin-top: -4.5rem !important;
}
.pb-6, .py-6 {
    padding-bottom: 4.5rem !important;
}
.card-header:first-child {
    border-radius: calc(.375rem - 1px) calc(.375rem - 1px) 0 0;
}
.card-header {
    margin-bottom: 0;
    padding: 1.25rem 1.5rem;
    border-bottom: 1px solid rgba(0,0,0,.05);
    background-color: #fff;
}
.card-img, .card-img-top {
    border-top-left-radius: calc(.375rem - 1px);
    border-top-right-radius: calc(.375rem - 1px);
}
.card-img, .card-img-bottom, .card-img-top {
    width: 100%;
    flex-shrink: 0;
}
.card-profile-image {
    position: relative;
}
.card-profile-image img {
    position: absolute;
    left: 50%;
    max-width: 140px;
    transition: all .15s ease;
    transform: translate(-50%,-50%) scale(1);
    border: 3px solid #fff;
    border-radius: .375rem;
}
.card-profile-stats {
    padding: 1rem 0;
}
.card-profile-stats > div {
    margin-right: 1rem;
    padding: .875rem;
    text-align: center;
}
.card-profile-stats > div .heading {
    font-size: 1.1rem;
    font-weight: 700;
    display: block;
}
.card-profile-stats > div .description {
    font-size: .875rem;
    color: #adb5bd;
}
.font-weight-300 {
    font-weight: 300 !important;
}
.text-nowrap {
    white-space: nowrap !important;
}
.avatar-sm {
    width: 36px !important;
    height: 36px !important;
    font-size: .875rem;
}
.avatar {
    color: #fff;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    border-radius: .75rem;
    height: 48px;
    width: 48px;
    transition: all .2s ease-in-out;
}
.avatar img {
    width: 100%;
}
.w-75 {
    width: 75% !important;
}
.text-xs {
    font-size: .75rem !important;
}
.table td .progress {
    height: 3px;
    width: 120px;
    margin: 0;
}
.avatar-group .avatar + .avatar {
    margin-left: -1rem;
}
.avatar-group .avatar {
    position: relative;
    z-index: 2;
    border: 2px solid #fff;
}
.avatar-xs {
    width: 24px !important;
    height: 24px !important;
    font-size: .75rem;
}
.avatar.rounded-circle img, .rounded-circle {
    border-radius: 50% !important;
}
.text-sm {
    font-size: .875rem !important;
}
.timeline {
    position: relative;
}
.timeline-one-side .timeline-step, .timeline-one-side::before {
    left: 1rem;
}
.timeline::before {
    left: 50%;
    margin-left: -1px;
}
.timeline::before {
    content: "";
    position: absolute;
    top: 0;
    left: 1rem;
    height: 100%;
    border-right: 2px solid #dee2e6;
}
.timeline-block:first-child {
    margin-top: 0;
}
.timeline-one-side .timeline-step, .timeline-one-side::before {
    left: 1rem;
}
.timeline-step {
    left: 50%;
}
.timeline-step {
    position: absolute;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    left: 0;
    width: 26px;
    height: 26px;
    border-radius: 50%;
    background: #fff;
    text-align: center;
    transform: translateX(-50%);
    font-size: 1rem;
    font-weight: 600;
    z-index: 1;
}
.timeline-one-side .timeline-content {
    max-width: 30rem;
}
.timeline-one-side .timeline-content {
    width: auto;
}
.timeline-content {
    width: 38%;
}
.timeline-content {
    margin-left: 45px;
    padding-top: .35rem;
    position: relative;
    top: -6px;
}
.text-gradient.text-success {
    background-image: linear-gradient(310deg,#17ad37,#c1e823);
}
.timeline-step i, .timeline-step svg {
    line-height: 1.4;
}
.text-gradient {
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    position: relative;
    z-index: 1;
}
.text-gradient.text-danger {
    background-image: linear-gradient(310deg,#d60808,#ff6690);
}
.timeline-step i, .timeline-step svg {
    line-height: 1.4;
}
.text-gradient.text-info {
    background-image: linear-gradient(310deg,#2152ff,#21d4fd);
}
.text-gradient.text-warning {
    background-image: linear-gradient(310deg,#f53939,#fbcf33);
}