.bg-light {
  background-color: #e9ecef !important;
}
.section-height-75 {
  min-height: 75vh;
}
.page-header {
  padding: 0;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  background-size: cover;
  background-position: 50%;
}
.card {
  box-shadow: 0 20px 27px 0 rgba(0, 0, 0, 0.05);
}
.mt-xl-5 {
  margin-top: 3rem !important;
}
.card .card-header {
  padding: 1.5rem;
      padding-bottom: 1.5rem;
      border-bottom: 0px solid rgba(0,0,0,.125);

}
.card-header:first-child {
  border-radius: 1rem 1rem 0 0;
}
.text-center {
  text-align: center !important;
}
.bg-transparent {
  background-color: transparent !important;
}
.card .card-body {
  font-family: "Open Sans";
  padding: 1.5rem;
}
.card-body {
  flex: 1 1 auto;
  padding: 1rem 1rem;
}
label, .form-label {
  font-size: 0.75rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
  color: #252f40;
  margin-left: 0.25rem;
}
.form-control {
  display: block;
  width: 100%;
  padding: 1rem 0.75rem;
  font-size: 0.975rem;
  font-weight: 400;
  line-height: 1.4rem;
  color: #333;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d2d6da;
  appearance: none;
  border-radius: 0.2rem;
  transition: box-shadow 0.15s ease, border-color 0.15s ease;
}
.form-switch {
  padding-left: 3rem;
}
.form-check {
  display: block;
  min-height: 1.5rem;
  padding-left: 1.73em;
  margin-bottom: 0.125rem;
}
.form-switch .form-check-input:checked {
  border-color: rgba(58, 65, 111, 0.95);
  background-color: rgba(58, 65, 111, 0.95);
}
.form-switch .form-check-input:checked {
  background-position: right center;
  background-image: none;
}
.form-check-input:checked[type="checkbox"] {
  background-image: linear-gradient(310deg, #141727 0%, #3A416F 100%);
}
.form-switch .form-check-input {
  border: 1px solid #e9ecef;
      border-top-color: rgb(233, 236, 239);
      border-right-color: rgb(233, 236, 239);
      border-bottom-color: rgb(233, 236, 239);
      border-left-color: rgb(233, 236, 239);
  position: relative;
  background-color: rgba(58, 65, 111, 0.1);
  height: 1.25em;
}
.form-check-label, .form-check-input[type="checkbox"] {
  cursor: pointer;
}
.form-switch .form-check-input {
  width: 2.5rem;
  margin-left: -3rem;
  background-image: none;
  background-position: left center;
  border-radius: 2.5rem;
  transition: background-color 0.25s ease, border-color 0.25s ease, background-position 0.15s ease-in-out, opacity 0.15s ease-out, box-shadow 0.15s ease-in-out;
}
.form-check-input:checked {
  background-color: transparent;
  border-color: transparent;
}

.form-check .form-check-input {
  float: left;
  margin-left: -1.73em;
}
.form-check-input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
a {
  letter-spacing: -0.025rem;
  color: #000;
}
.form-check-input {
  width: 1.23em;
  height: 1.23em;
  margin-top: 0.135em;
  vertical-align: top;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: none;
  appearance: none;
  color-adjust: exact;
  transition: background-color 0.25s ease, border-color 0.25s ease, background-position 0.15s ease-in-out, opacity 0.15s ease-out, box-shadow 0.15s ease-in-out;
}
.form-check-input {
  color-adjust: exact;
}
.form-switch .form-check-input:checked::after {
  transform: translateX(21px);
}
.form-switch .form-check-input::after {
  transition: transform 0.25s ease-in-out, background-color 0.25s ease-in-out;
  content: "";
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  position: absolute;
  background-color: #fff;
  transform: translateX(1px);
  box-shadow: 0 0.25rem 0.375rem -0.0625rem rgba(20, 20, 20, 0.12), 0 0.125rem 0.25rem -0.0625rem rgba(20, 20, 20, 0.07);
  top: 1px;
}
.text-bold {
  font-weight: 600;
}
.text-decoration-none {
  text-decoration: none !important;
}

.bg-primary {
  background-color: #367ebd  !important;
}
.me-xl-5 {
  margin-right: 3rem !important;
}
a:hover {
  color: #830866;
  text-decoration: none;
}
.me-xl-5 {
  margin-right: 3rem !important;
}
.mb-sm-0 {
  margin-bottom: 0 !important;
}
.container, .container-sm, .container-md, .container-lg, .container-xl, .container-xxl {
  max-width: 1320px;
}
.nav-footer .nav-link {
  font-size: .875rem;
}
.h2, h2 {
  font-size: 1.25rem;
}

.btn-neutral {
  color: #7b2fe3;
}
.table td, .table th {
  font-size: .8125rem;
  white-space: normal;
}
.pray_text {
  max-width: 450px;
}
.table-flush tbody tr:first-child td, .table-flush tbody tr:first-child th {
  border-top: 0;
}
.card .table td, .card .table th {
  padding-right: 1.5rem;
  padding-left: 1.5rem;
}
.custom-toggle {
  position: relative;
  display: inherit;
  display: inline-block;
  width: 52px;
  height: 1.5rem;
  margin: 0;
}
.custom-toggle input {
  display: none;
}
input[type="checkbox"], input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}
.custom-toggle input:checked + .custom-toggle-slider {
  border: 1px solid #7b2fe3;
}
.custom-toggle-slider {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  cursor: pointer;
  border: 1px solid #ced4da;
  border-radius: 34px !important;
  background-color: transparent;
}
.avatar.rounded-circle img, .rounded-circle {
  border-radius: 50% !important;
}
.custom-toggle input:checked + .custom-toggle-slider::before {
  transform: translateX(28px);
  background: #7b2fe3;
}
.custom-toggle-slider::before {
  position: absolute;
  bottom: 2px;
  left: 2px;
  width: 18px;
  height: 18px;
  content: '';
  transition: all .15s cubic-bezier(.68,-.55,.265,1.55);
  border-radius: 50% !important;
  background-color: #e9ecef;
}
.custom-toggle input:checked + .custom-toggle-slider::after {
  right: auto;
  left: 0;
  content: attr(data-label-on);
  color: #7b2fe3;
}
.custom-toggle-slider::after {
  font-family: inherit;
  font-size: .75rem;
  font-weight: 600;
  line-height: 24px;
  position: absolute;
  top: 0;
  right: 0;
  display: block;
  overflow: hidden;
  min-width: 1.66667rem;
  margin: 0 .21667rem;
  content: attr(data-label-off);
  transition: all .15s ease;
  text-align: center;
  color: #ced4da;
}
.form-control{
  padding: .6rem .75rem !important;
}
.form-control-label {
  font-size: .875rem;
  font-weight: 600;
  color: #2f3135;
}
.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 1.25rem + 2px);
  margin-bottom: 0;
}
.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 1.25rem + 2px);
  margin: 0;
  opacity: 0;
}
.custom-file-input {
  width: 60% !important;
  margin: 5px !important;
  opacity: 1 !important;
}
.custom-control-label::before, .custom-file-label, .custom-select {
  transition: background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.custom-file-label {
  font-weight: 400;
  line-height: 1.5;
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  left: 0;
  height: calc(1.5em + 1.25rem + 2px);
  padding: .625rem .75rem;
  color: #8898aa;
  border: 1px solid #dee2e6;
  border-radius: .25rem;
  background-color: #fff;
  box-shadow: 0 3px 2px rgba(233,236,239,.05);
}
.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: 'Browse';
}

.custom-control-label::after{
  top: 0.5rem !important;
}
.custom-file-label::after {
  line-height: 1.5;
  position: absolute;
  z-index: 3;
  top: 0;
  right: 0;
  bottom: 0;
  display: block;
  height: calc(1.5em + 1.25rem);
  padding: .625rem .75rem;
  content: 'Browse';
  color: #8898aa;
  border-left: inherit;
  border-radius: 0 .25rem .25rem 0;
  background-color: #fff;
}
.pagination {
  margin: 15px auto 10px;
  padding-bottom: 2rem;
  justify-content: end;
}
.card-header h3 {
  font-size: 20px;
}
.pagination > .active > a{
  background-color: #6200ea;
  border-color: #7b2fe3;
  color: #fff;
}
.pagination > li > a{
  border: 1px solid #7b2fe3 ;
  padding: 5px 10px;
  outline: none;
  cursor: pointer;
}
.pagination > .active > a, .pagination > .active > span, .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus, .pagination > .active > span:focus{
  background-color: #6200ea;
  border-color: #7b2fe3;
  outline: none ;
}
.pagination > li > a, .pagination > li > span{
  color: #47ccde
}
.pagination > li:first-child > a, .pagination > li:first-child > span, .pagination > li:last-child > a, .pagination > li:last-child > span{
  border-radius: unset
}
.svg-inline--fa.fa-play.fa-w-14 {
  color: #04d611;
}
.loader_block {
    position: fixed;
    display: block;
    left: 0%;
    top: 0%;
    width: 100%;
    height: 100%!important;
    z-index: 9999;
    background-color: rgba(255, 255, 255, 0.7);
}

.loader_block span {
    left: 50%;
    top: 50%;
    position: relative;
}

.hide-display {
    display: none;
}

.Toastify__toast{
padding:17px !important;
}